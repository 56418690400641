const PostFormContent = () => {
  return (
    <section className="content">
      <div>
        <h1>Money-Back Guarantee</h1>
        <p>
          We are confident in Ponagon’s effectiveness. If you are unsatisfied
          within 30 days, we will offer a full refund—no questions asked.
        </p>
      </div>
      <br />
      <div>
        <h1>Have Questions? We are here to help!</h1>
        <p>Phone: 08086563502</p>
        <p>Email: forklifesolutions@gmail.com</p>
        <p>WhatsApp: 08147912128</p>
      </div>
    </section>
  );
};

export default PostFormContent;
